<template>
	<v-layout row wrap>
		<v-flex align-self-start shrink>
			<v-switch v-model="isShow" color="primary" :label="$t('customer.users.revocated_at')" @change="toggleDate()"></v-switch>
		</v-flex>
		<v-flex v-if="mustShow()" xs1 pt-4>
			<p v-t="`customer.users.revocated_le`" class="text-xs-center"></p>
		</v-flex>
		<v-flex v-if="mustShow()" align-self-start shrink>
			<v-menu
				ref="menuDate"
				v-model="menuDate"
				:close-on-content-click="false"
				lazy
				transition="scale-transition"
				offset-y
				full-width
				max-width="290px"
				min-width="290px"
			>
				<template v-slot:activator="{ on }">
					<v-flex shrink>
						<v-text-field v-model="dateFormatted" hide-details class="text-xs-center" readonly v-on="on"></v-text-field>
					</v-flex>
				</template>
				<v-date-picker
					v-model="date"
					color="primary"
					:first-day-of-week="$firstDayOfTheWeek()"
					:locale="$i18n.locale"
					no-title
					@input="menuDate = false"
				></v-date-picker>
			</v-menu>
		</v-flex>
		<v-flex v-if="mustShow()" xs1 pt-4>
			<p v-t="`customer.users.revocated_a`" class="text-xs-center"></p>
		</v-flex>
		<v-flex v-if="mustShow()" align-self-start xs2 lg1>
			<v-menu
				ref="menuTime"
				v-model="menuTime"
				:close-on-content-click="false"
				:return-value.sync="time"
				lazy
				transition="scale-transition"
				offset-y
				full-width
				max-width="290px"
				min-width="290px"
			>
				<template v-slot:activator="{ on }">
					<v-flex shrink>
						<v-text-field v-model="timeFormatted" hide-details readonly class="text-xs-center" v-on="on"></v-text-field>
					</v-flex>
				</template>
				<v-time-picker
					v-if="menuTime"
					v-model="time"
					color="primary"
					format="24hr"
					class="text-xs-center"
					full-width
					@click:minute="$refs.menuTime.save(time)"
				></v-time-picker>
			</v-menu>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'UserRevocationDate',
	props: {
		value: {
			type: String,
			default: null
		}
	},
	data: function () {
		return {
			isShow: false,
			menuDate: false,
			menuTime: false,
			date: null,
			time: null
		}
	},
	computed: {
		datetime: function () {
			if (this.date && this.time) {
				return this.date + ' ' + this.time
			} else {
				return null
			}
		},
		dateFormatted: function () {
			return this.$d(new Date(this.date), 'regular')
		},
		timeFormatted: function () {
			return this.time.split(':')[0] + 'h' + this.time.split(':')[1]
		}
	},
	watch: {
		date: function () {
			this.emitInput()
		},
		time: function () {
			this.emitInput()
		},
		value: function () {
			this.initValue()
		}
	},
	created: function () {
		this.initValue()
	},
	methods: {
		toggleDate: function () {
			if (this.isShow) {
				this.date = new Date().toISOString().substr(0, 10)
				this.time = ('0' + new Date().getHours()).slice(-2) + ':' + ('0' + new Date().getMinutes()).slice(-2)
				this.$emit('input', this.datetime)
			} else {
				this.date = null
				this.time = null
				this.$emit('input', null)
			}
		},
		mustShow: function () {
			return this.isShow && this.datetime
		},
		emitInput: function () {
			if (this.datetime) {
				this.$emit('input', this.datetime)
			} else {
				this.$emit('input', null)
			}
		},
		initValue: function () {
			if (this.value !== null) {
				this.isShow = true
				this.date = new Date(this.value).toISOString().substr(0, 10)
				this.time = ('0' + new Date(this.value).getHours()).slice(-2) + ':' + ('0' + new Date(this.value).getMinutes()).slice(-2)
			} else {
				this.isShow = false
				this.date = null
				this.time = null
			}
		}
	}
}
</script>
